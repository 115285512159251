<template>
  <div id="threescene">
    <v-overlay v-if="sceneLoading">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <canvas ref="canvasdiv" id="canvas" height="1024" width="1024"></canvas>
    <div ref="container" :style="'width: ' +  widthScene + 'px; height: ' + heightScene + 'px;'"></div>
    <v-navigation-drawer
        right
        v-model="drawerRight"
        :width="widthScene + 'px'"
        :clipped="$vuetify.breakpoint.lgAndUp"
        app
        class="pa-4"
    >
      <v-stepper
          non-linear
          v-model="step"
          vertical>
        <v-stepper-step editable step="1">
          {{ $t('ThreeScene.3dModel') }}
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-row>
            <v-col
            >
              <v-file-input @click:append="objComputed ? $store.dispatch('downloadFile', currentProduct.objPath) : null" :append-icon="objComputed ? 'mdi-download-circle' : null" color="primary" v-model="inputFileObj" outlined accept=".obj"
              @change='showOriginalFileName = false;' :label="$t('ProductsForm.AddProduct')" :prefix="objComputed"/>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-step
            editable
            step="2">
            {{ $t('ThreeScene.ProductColorsMaterials') }}
        </v-stepper-step>
        <v-stepper-content step="2">
          <v-tabs
              v-model="tabMaterial"
              grow
              style="margin-bottom: 30px"
          >
            <v-tab
                v-for="(material, index) in productMaterials"
                :key="'tab' + index"
            >
              {{ material.name }}
              <v-btn @click.stop="removeMaterial(index)" icon v-if="productMaterials.length > 1"><v-icon>mdi-close</v-icon></v-btn>
            </v-tab>
            <template v-if="allowAddingMaterials">
              <v-btn icon @click="addMaterial">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
          </v-tabs>

          <v-tabs-items v-model="tabMaterial">
            <v-tab-item
                v-for="(material, index) in productMaterials"
                :key="'tab' + index"
            >
              <v-row>
                <v-col md="3">
                  <v-text-field outlined v-model="material.sku" :label="$t('General.SKU')"></v-text-field>
                </v-col>
                <v-col md="3">
                  <v-text-field outlined v-model="material.name" :label="$t('ThreeScene.MaterialName')"></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-select v-model="material.mappingType" outlined :label="$t('ThreeScene.MappingType')"
                            :items="mappingType"
                            ></v-select>
                </v-col>
              </v-row>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th v-t="'General.SKU'" class='text-center'></th>
                    <th v-t="'ThreeScene.ProductReference'" class='text-center'></th>
                    <th v-t="'General.Edit'" class='text-center'></th>
                    <th v-t="'General.Delete'" class='text-center'></th>
                  </tr>
                  </thead>
                  <tbody v-if="currentProduct.textures && currentProduct.textures[tabMaterial]">
                  <tr v-for='(color, index) in currentProduct.textures[tabMaterial].colorList' :key='color.sku'>
                    <td>{{ color.sku }}</td>
                    <td>{{ color.productRef }}</td>
                    <td @click="editColor(color)">
                      <v-btn icon>
                        <v-icon>mdi-border-color</v-icon>
                      </v-btn>
                    </td>
                    <td @click='removeColor(index)'>
                      <v-btn icon>
                        <v-icon color='red'>mdi-close-circle-outline</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <div>
                <v-btn color="primary" class="text-right ma-5" @click="addColor" v-t="'ThreeScene.AddColor'"></v-btn>
              </div>
              <div v-if="colors">
                <v-text-field
                    v-model="colors.sku"
                    :label="$t('ThreeScene.SKUCorrespondence')"
                    outlined
                ></v-text-field>
                <v-text-field
                    v-model="colors.productRef"
                    :label="$t('ThreeScene.ProductReference')"
                    outlined
                ></v-text-field>
                <div style="display: flex; flex-flow: row nowrap; justify-content: space-between; align-items: center; margin-bottom: 30px;">
                  <div style="display: flex; flex-flow: row nowrap; align-items: center; gap: 10px;">
                    Color :
                    <v-menu top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                      <template v-slot:activator="{ on }">
                        <div
                            :style="'backgroundColor: ' + colors.color + '; cursor: pointer; borderRadius: 50%; borderColor: black; borderStyle: solid; height: 30px; width: 30px;'"
                            v-on="on"/>
                      </template>
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-color-picker v-model="colors.color" @input="changeColor" mode="rgba" dot-size="25" flat/>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                  <div style="display: flex; flex-flow: row nowrap; align-items: center; gap: 10px;">
                    Emissive :
                    <v-menu top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                      <template v-slot:activator="{ on }">
                        <div
                            :style="'backgroundColor: ' + colors.emissive + '; cursor: pointer; borderRadius: 50%; borderColor: black; borderStyle: solid; height: 30px; width: 30px;'"
                            v-on="on"/>
                      </template>
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-color-picker v-model="colors.emissive" @input="changeEmissive" mode="rgba" dot-size="25" flat/>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                  <div style="display: flex; flex-flow: row nowrap; align-items: center; gap: 10px;">
                    Preview :
                    <v-menu top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                      <template v-slot:activator="{ on }">
                        <div
                            :style="'backgroundColor: ' + colors.preview + '; cursor: pointer; borderRadius: 50%; borderColor: black; borderStyle: solid; height: 30px; width: 30px;'"
                            v-on="on"/>
                      </template>
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-color-picker v-model="colors.preview" mode="rgba" dot-size="25" flat/>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </div>
                <div style="display: flex" v-t="'ThreeScene.ConfiguratorPreview'">
                  <v-menu top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                      <div
                          :style="'backgroundColor: ' + colors.preview + '; cursor: pointer; borderRadius: 50%; margin-left: 30px; borderColor: black; borderStyle: solid; height: 30px; width: 30px;'"
                          v-on="on"/>
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="colors.preview" mode="rgba" dot-size="25" flat/>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </div>
              </div>
              <v-row>
                <v-col>
                  <v-select @change="changeExtMaterial" v-model="extTexture" outlined :label="$t('ThreeScene.ExternalMaterial')"
                            :items="materials" item-text="name"
                            return-object></v-select>
                </v-col>
                <v-col>
                  <v-select @change="changeIntMaterial" v-model="intTexture" outlined :label="$t('ThreeScene.InternalMaterial')"
                            :items="materials" item-text="name"
                            return-object></v-select>
                </v-col>
              </v-row>
              <v-row v-for="(child, index) in childrenToChange" :key="child.id">
                <v-col>
                  <v-select @change="changeMaterial(child, index)" v-model="otherTextures[index]" outlined :label="child.name"
                            :items="materials" item-text="name"
                            return-object></v-select>
                </v-col>
                <v-col v-if="otherTextures[index]" style="display: flex; align-items: center; justify-content: center; padding-bottom: 3%;">
                  Color :
                  <v-menu top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                      <div
                          :style="'backgroundColor: ' + (otherColors[index] ? otherColors[index].hex : '#FFF') + '; cursor: pointer; borderRadius: 50%; margin-left: 30px; borderColor: black; borderStyle: solid; height: 30px; width: 30px;'"
                          v-on="on"/>
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker
                            v-model="otherColors[index]"
                            flat
                            mode="rgba"
                            dot-size="25"
                            @input="changeColorChildren(child, index, true)"
                        ></v-color-picker>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-col>
                <v-col v-if="otherTextures[index]" style="display: flex; align-items: center; justify-content: center; padding-bottom: 3%;">
                  Emissive :
                  <v-menu top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                      <div
                          :style="'backgroundColor: ' + (otherStrokes[index] ? otherStrokes[index].hex : '#FFF') + '; cursor: pointer; borderRadius: 50%; margin-left: 30px; borderColor: black; borderStyle: solid; height: 30px; width: 30px;'"
                          v-on="on"/>
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker
                            v-model="otherStrokes[index]"
                            flat
                            mode="rgba"
                            dot-size="25"
                            @input="changeEmissiveChildren(child, index, true)"
                        ></v-color-picker>
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-stepper-content>

        <v-stepper-step
          editable
          step="3"
        >
          {{ $t('ThreeScene.Template') }}
        </v-stepper-step>

        <v-stepper-content step="3">

          <v-tabs
            v-model="tabPatronConfig"
            grow
            style="display: none;"
          >
            <v-tab
              v-for="(patronConfig, index) in patronConfigList"
              :key="'tab' + index"
            >
              SKU {{ impressions[index].sku }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tabPatronConfig">
            <v-tab-item
              v-for="(patronConfig, index) in patronConfigList"
              :key="'tab' + index"
            >
              <v-container v-if="fileInputs.length">
                <!-- <v-row>
                  <v-col style="align-items: center">
                    SKU : {{ impressions[index].sku }} - Hauteur : {{ impressions[index].hauteur }}
                  </v-col>
                </v-row> -->
                <v-row>
                  <v-col>
                    <v-text-field v-model="patronConfig.width"
                                  type="number"
                                  min="0"
                                  :label="$t('ThreeScene.Width')"
                                  outlined
                                  @input="updatePosPatron"/>
                  </v-col>
                  <v-col>
                    <v-text-field v-model="patronConfig.height"
                                  type="number"
                                  min="0"
                                  :label="$t('ThreeScene.Height')"
                                  outlined
                                  @input="updatePosPatron"/>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field v-model="patronConfig.proofWidth"
                                  type="number"
                                  min="0"
                                  :label="$t('ThreeScene.ProofWidth')"
                                  outlined
                                  :hint="$t('ThreeScene.InfosOnProofOnly')"
                                  @input="updatePosPatron"/>
                  </v-col>
                  <v-col>
                    <v-text-field v-model="patronConfig.proofHeight"
                                  type="number"
                                  min="0"
                                  :label="$t('ThreeScene.ProofHeight')"
                                  outlined
                                  :hint="$t('ThreeScene.InfosOnProofOnly')"
                                  @input="updatePosPatron"/>
                  </v-col>
                  <v-col>
                    <v-text-field v-model="patronConfig.height"
                                  type="number"
                                  min="0"
                                  :label="$t('ThreeScene.Height')"
                                  outlined
                                  @input="updatePosPatron"/>
                  </v-col>
                </v-row>
                <v-row>
                  <v-file-input color="primary" outlined class="ma-5" v-model="fileInputs[index].ai"
                                accept="application/pdf"
                                :label="$t('ThreeScene.AddTemplateAI')"/>
                </v-row>
                <v-row>
                  <v-file-input color="primary" outlined class="ma-5" v-model="fileInputs[index].psd"
                                accept="image/vnd.adobe.photoshop"
                                :label="$t('ThreeScene.AddTemplatePSD')"/>
                </v-row>
                <v-row>
                  <v-file-input color="primary" outlined class="ma-5" v-model="fileInputs[index].pdf"
                                accept="application/pdf"
                                :label="$t('ThreeScene.AddTemplatePDF')"/>
                </v-row>
                <v-row class="pa-2">
                  <v-col>
                    <v-select
                      outlined
                      deletable-chips
                      multiple
                      :label="$t('General.Measures')"
                      v-model="currentProduct.measureList[index]"
                      :items="measures"
                      item-text="name"
                      item-value="id"
                    >
                      <template v-slot:selection="{ item }">
                        <v-chip>{{ item.name }}
                          <v-btn icon @click.stop="showMeasure(item)">
                            <v-icon>mdi-eye</v-icon>
                          </v-btn>
                        </v-chip>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-stepper-content>

        <v-stepper-step
            editable
            step="4"
        >
            {{ $t('ThreeScene.CustomizationZone') }}
        </v-stepper-step>

        <v-stepper-content step="4">

          <v-tabs
              v-model="tabMaskConfig"
              grow
              style="display: none;"
          >
            <v-tab
                v-for="(maskConfig, index) in maskConfigList"
                :key="'tab' + index"
            >
              SKU {{ impressions[index].sku }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tabMaskConfig">
            <v-tab-item
                v-for="(maskConfig, index) in maskConfigList"
                :key="'tab' + index"
            >
              <v-container>
                <!-- <v-row>
                  <v-col style="align-items: center">
                    SKU : {{ impressions[index].sku }} - Hauteur : {{ impressions[index].hauteur }}
                  </v-col>
                </v-row> -->
                <v-row>
                  <v-col style="align-items: center;">
                    <v-select
                      @change="switchUvmapType"
                      v-model="currentProduct.uvmapType"
                      item-text="label"
                      item-value="value"
                      outlined
                      :label="$t('ThreeScene.UVMapType')"
                      :items="uvMapTypes"
                    ></v-select>
                  </v-col>
                </v-row>
                <template v-if="!currentProduct.uvmapType || currentProduct.uvmapType === 'manual'">
                  <v-row>
                    <v-col>
                      <v-text-field v-model="maskConfig.top"
                                    type="number"
                                    min="0"
                                    step="5"
                                    :label="$t('ThreeScene.VerticalPosition')"
                                    outlined
                                    @input="updatePosMask(maskConfig)"/>
                    </v-col>
                    <v-col
                    >
                      <v-text-field v-model="maskConfig.left"
                                    type="number"
                                    min="0"
                                    step="5"
                                    :label="$t('ThreeScene.HorizontalPosition')"
                                    outlined
                                    @input="updatePosMask(maskConfig)"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field v-model="maskConfig.height"
                                    type="number"
                                    min="0"
                                    max="1024"
                                    step="5"
                                    :label="$t('ThreeScene.Height')"
                                    outlined
                                    @input="updateHeightMask(index)"/>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="maskConfig.width"
                                    type="number"
                                    min="0"
                                    max="1024"
                                    step="5"
                                    :label="$t('ThreeScene.Width')"
                                    outlined
                                    @input="updateWidthMask(index)"/>
                    </v-col>
                  </v-row>
                </template>
                <template v-else>
                  <v-row>
                    <v-col>
                      <v-text-field v-model="uvMapManager.fullHeight"
                                    type="number"
                                    min="0"
                                    step="5"
                                    label="Full height"
                                    outlined
                                    @input="updateUvmap(maskConfig, index)"/>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="uvMapManager.printHeight"
                                    type="number"
                                    min="0"
                                    step="5"
                                    label="Print height"
                                    outlined
                                    @input="updateUvmap(maskConfig, index)"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field v-model="uvMapManager.perimeter"
                                    type="number"
                                    min="0"
                                    step="5"
                                    label="Perimeter"
                                    outlined
                                    @input="updateUvmap(maskConfig, index)"/>
                    </v-col>
                    <v-col>
                      <v-text-field v-model="uvMapManager.topOffset"
                                    type="number"
                                    min="0"
                                    max="1024"
                                    step="5"
                                    label="Offset top"
                                    outlined
                                    @input="updateUvmap(maskConfig, index)"/>
                    </v-col>
                  </v-row>
                </template>
                {{maskConfig}}
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-stepper-content>

        <v-stepper-step
            editable
            step="5"
        >
            {{ $t('ThreeScene.CameraSettings') }}
        </v-stepper-step>

        <v-stepper-content step="5">
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col>
                  <v-range-slider
                      v-model="cameraConfig.distance"
                      min="0"
                      max="100"
                      step="1"
                      :label="$t('ThreeScene.CameraDistance')"
                      @input="updateDistanceCamera"
                      @mouseup="updateCamera"
                  ></v-range-slider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-range-slider
                      v-model="cameraConfig.polarAngle"
                      min="0"
                      :max="Math.PI"
                      step="0.005"
                      :label="$t('ThreeScene.CameraAngle')"
                      @input="updateAngleCamera"
                      @mouseup="updateCamera"
                  ></v-range-slider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-slider
                      vertical
                      v-model="cameraConfig.target.y"
                      :label="$t('ThreeScene.TargetHeight')"
                      @input="updateCamera"
                      step="1"
                      min="0"
                      max="20"
                  >
                  </v-slider>
                </v-col>
              </v-row>
              <v-row style="justify-content: center">
                <v-btn @click="setDefaultCameraPosition" v-t="'ThreeScene.SetDefaultCameraPosition'"></v-btn>
              </v-row>
            </v-container>
          </v-form>
        </v-stepper-content>
        <v-stepper-step
            editable
            step="6"
        >
            {{ $t('ThreeScene.Lights') }}
        </v-stepper-step>

        <v-stepper-content step="6">
          <v-row>
            <v-col>
              <v-list>
                <v-list-item v-for="(light, index) in lights" :key="light.type+index">
                  <v-list-item-title>{{ light.type }}</v-list-item-title>
                  <v-list-item-action>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            color="secondary"
                            icon
                            dark
                            @click="selectLight(light)"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span v-t="'General.Edit'"></span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                            v-on="on"
                            color="red"
                            icon
                            @click='deleteLight(light)'
                        >
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </template>
                      <span v-t="'General.Delete'"></span>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <v-select :items="lightsList" v-model="lightToAdd"></v-select>
                  </v-list-item-title>
                  <v-list-item-action>
                    <v-btn icon @click="addLight(lightToAdd)">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <div v-if="lightSelected">
            <v-divider></v-divider>
            <v-row>
              <v-col>
                <v-slider label="Intensité" min="0" max="1" step="0.01" thumb-label
                          v-model="lightSelected.intensity"></v-slider>
              </v-col>
            </v-row>
            <v-row v-if="lightSelected.target">
              <v-col>
                <v-slider vertical label="Angle" min="-50" max="50" step="1" thumb-label
                          v-model="lightSelected.target.position.y" @input="updateLightTarget"></v-slider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-color-picker v-model="lightColor"></v-color-picker>
              </v-col>
            </v-row>
          </div>
        </v-stepper-content>
      </v-stepper>
    </v-navigation-drawer>

    <v-snackbar
      :value="!allRatioAreGood"
      :timeout="-1"
      color="primary"
      top
    >
    {{ $t('ThreeScene.TemplateNotProportional') }}
    </v-snackbar>
  </div>
</template>

<script>
import * as Three from 'three'
import { fabric } from 'fabric'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import EventBus from '../bus.js'
import { TransformControls } from 'three/examples/jsm/controls/TransformControls'
import { UvMapManager } from '../uvMapManager.js'

var OrbitControls = require('three-orbit-controls')(Three)
const allowAddingMaterials = false

export default {
  name: 'ThreeScene',
  data () {
    return {
      drawerRight: true,
      colors: null,
      items: [
        { icon: 'mdi-camera', text: 'Caméra', mode: 'camera' },
        { icon: 'mdi-format-color-fill', text: 'Couleur', mode: 'color' },
        { icon: 'mdi-cog', text: 'Produit', mode: 'product' }
      ],
      camera: null,
      currentProduct: {},
      objectLoaded: null,
      scene: null,
      showReperes: false,
      renderer: null,
      controls: null,
      languageList: null,
      container: null,
      txtmask: null,
      rectmask: null,
      measureShown: null,
      canvasdiv: null,
      canvas: null,
      impressions: [{ sku: 0, hauteur: '6cm' }],
      productMaterials: [{ sku: 'PP', textures: [] }],
      sceneLoading: true,
      step: 1,
      tabMaskConfig: 0,
      tabMaterial: 0,
      tabPatronConfig: 0,
      lights: [],
      patronConfigList: [{ width: 1, height: 1, proofWidth: 1, proofHeight: 1, pdf: null, psd: null, ai: null }],
      maskConfigList: [{ top: 0, left: 0, width: 1024, height: 1024 }],
      cameraLoaded: false,
      cameraConfig: {
        position: {
          x: 0,
          y: 16,
          z: 29
        },
        distance: [30, 50],
        polarAngle: [3 / 12 * Math.PI, 6 / 12 * Math.PI],
        target: {
          x: 0,
          y: 7,
          z: 0
        }
      },
      fileInputs: [],
      widthScene: window.innerWidth / 2,
      heightScene: window.innerHeight - 128,
      sliderModificationState: null,
      materialPresets: [],
      chosenTextures: ['', ''],
      extTexture: {},
      intTexture: {},
      otherTextures: [],
      otherStrokes: [],
      otherColors: [],
      materials: [],
      measures: [],
      lightsList: ['AmbientLight', 'DirectionalLight', 'HemisphereLight', 'PointLight', 'SpotLight'],
      lightSelected: null,
      lightControls: null,
      lightHelper: null,
      lightToAdd: null,
      allRatioAreGood: true,
      uvMapManager: {},
      uvMaps: [],
      mappingType: [
        'none',
        'variant',
        'product'
      ],
      uvMapTypes: [
        {
            label: 'Manual',
            value: 'manual'
        },
        {
            label: 'Automatic (Cylinder)',
            value: 'auto'
        }
      ],
      showOriginalFileName: true,
      inputFileObj: {}
    }
  },
  watch: {
    step: function (step) {
      this.rectmask.visible = true
      if (this.measureShown) {
        this.canvas.remove(this.measureShown)
        this.measureShown = null
      }
      if ((parseInt(step) === 3 || parseInt(step) === 2) && this.maskConfigList.length) {
        this.rectmask.visible = true
        this.updatePosMask(this.maskConfigList[this.tabMaskConfig])
      } else {
        this.canvas.requestRenderAll()
        this.rectmask.visible = false
        this.canvas.renderAll()
      }
      if (parseInt(step) !== 5) {
        if (this.lightControls) {
          this.scene.remove(this.lightControls)
          this.lightControls = null
        }
        if (this.lightHelper) {
          this.scene.remove(this.lightHelper)
          this.lightHelper = null
        }
        this.lightSelected = null
      }
    },
    tabMaskConfig: function (maskConfig) {
      this.updatePosMask(this.maskConfigList[maskConfig])
    },
    tabMaterial: function (material) {
      if (!this.currentProduct.textures[material]) return
      this.otherColors = JSON.parse(JSON.stringify(this.currentProduct.textures[material]['otherColors'])) || []
      this.otherStrokes = JSON.parse(JSON.stringify(this.currentProduct.textures[material]['otherEmissivesColors'])) || []
      this.chosenTextures = this.currentProduct.textures[material].textures
      this.colors = null
      while (this.chosenTextures.length < (this.objectLoaded.children.length - 2)) {
        this.chosenTextures.push('')
      }
      this.currentProduct.textures[material].textures.forEach((texture, indexTexture) => {
        if (indexTexture === 1 && texture) {
          this.extTexture = this.materials.find((material) => {
            return material.id === texture
          })
          this.changeExtMaterial(this.extTexture)
        } else if (indexTexture === 0 && texture) {
          this.intTexture = this.materials.find((material) => {
            return material.id === texture
          })
          this.changeIntMaterial(this.intTexture)
        } else if (indexTexture >= 2 && texture) {
          this.otherTextures[indexTexture - 2] = this.materials.find((material) => {
            return material.id === texture
          })
          this.changeMaterial(this.objectLoaded.children[indexTexture + 2], indexTexture - 2)
        }
      })
      if (this.currentProduct.textures[material].colorList.length > 0) {
        this.changeColor(this.currentProduct.textures[material].colorList[0].color)
        this.changeEmissive(this.currentProduct.textures[material].colorList[0].emissive)
      }
      this.childrenToChange.forEach((child, index) => {
        if (this.otherColors.length > 0) this.changeColorChildren(child, index, false)
        if (this.otherStrokes.length > 0) this.changeEmissiveChildren(child, index, false)
      })
    },
    tabPatronConfig: function (patronConfig) {
      if (this.measureShown) {
        this.canvas.remove(this.measureShown)
        this.measureShown = null
      }
      this.updatePosMask(this.maskConfigList[patronConfig])
    },
    otherStrokes: function (strokes) {
      this.currentProduct.textures[this.tabMaterial]['otherEmissivesColors'] = JSON.parse(JSON.stringify(strokes))
    },
    otherColors: function (colors) {
      this.currentProduct.textures[this.tabMaterial]['otherColors'] = JSON.parse(JSON.stringify(colors))
    },
    inputFileObj: function (file) {
      if (file.name) {
        this.addObj()
      }
    }
  },
  computed: {
    hasModelChanged () {
      return !!this.inputFileObj.name
    },
    childrenToChange () {
      return this.objectLoaded ? this.objectLoaded.children.filter((child, index) => {
        return index >= 4
      }) : []
    },
    lightColor: {
      get () {
        return {
          r: this.lightSelected.color.r * 255,
          g: this.lightSelected.color.g * 255,
          b: this.lightSelected.color.b * 255
        }
      },
      set (val) {
        this.lightSelected.color = new Three.Color('rgb(' + val.r + ', ' + val.g + ', ' + val.b + ')')
      }
    },
    objComputed () {
      return this.showOriginalFileName ? this.getFileName(this.currentProduct.objPath) : ''
    }
  },
  methods: {
    updateUvmap (maskConfig, index = 0) {
      const maskSize = 1024
      maskConfig.height = (1 / this.uvMapManager.ratio * 1024)
      maskConfig.top = ((maskSize - parseInt(maskConfig.height)) / 2)
      this.updatePosMask(maskConfig)
      this.uvMaps[index] = this.uvMapManager.loadUVMap()
    },
    checkIfAllRatioAreGood () {
      this.allRatioAreGood = true
      this.maskConfigList.forEach((maskConfig, index) => {
        const ratioMask = maskConfig.height / maskConfig.width
        const ratioPatron = this.patronConfigList[index].height / this.patronConfigList[index].width
        if (Math.abs(ratioPatron - ratioMask) > 0.001) {
          this.allRatioAreGood = false
        }
      })
    },
    showMeasure (measure) {
      if (this.measureShown) {
        this.canvas.remove(this.measureShown)
        this.measureShown = null
      }
      fabric.loadSVGFromURL(this.$axios.defaults.baseURL + '/downloadSvg?filename=' + measure.measurePath, (objects, options) => {
        var graduation = fabric.util.groupSVGElements(objects, options)
        graduation.set({
          originX: 'left',
          originY: 'top',
          left: 0,
          top: this.rectmask.top,
          scaleX: this.rectmask.height * this.rectmask.scaleY / graduation.height,
          scaleY: this.rectmask.height * this.rectmask.scaleY / graduation.height
        })
        this.measureShown = graduation
        this.canvas.add(graduation)
        this.canvas.renderAll()
      })
    },
    updateLightTarget () {
      this.lightSelected.target.updateMatrixWorld()
      if (this.lightHelper) {
        this.lightHelper.update()
      }
    },
    addLight (type) {
      if (!type) {
        return
      }
      var light
      switch (type) {
        case 'DirectionalLight':
          light = new Three.DirectionalLight()
          break
        case 'PointLight':
          light = new Three.PointLight()
          break
        case 'SpotLight':
          light = new Three.SpotLight()
          break
        case 'HemishpereLight':
          light = new Three.HemisphereLight()
          break
        case 'AmbientLight':
          light = new Three.AmbientLight()
          break
      }
      light.color = new Three.Color('rgb(255, 255, 255)')
      this.scene.add(light)
      this.lights.push(light)
      this.selectLight(light)
    },
    deleteLight (light) {
      if (this.lightControls) {
        this.scene.remove(this.lightControls)
      }
      if (this.lightHelper) {
        this.scene.remove(this.lightHelper)
      }
      this.lights.splice(this.lights.indexOf(light), 1)
      this.scene.remove(light)
    },
    selectLight (light) {
      if (this.lightControls) {
        this.scene.remove(this.lightControls)
      }
      if (this.lightHelper) {
        this.scene.remove(this.lightHelper)
      }
      this.lightControls = new TransformControls(this.camera, this.renderer.domElement)
      this.lightSelected = light
      this.lightHelper = null
      switch (light.type) {
        case 'DirectionalLight':
          this.lightHelper = new Three.DirectionalLightHelper(light)
          break
        case 'PointLight':
          this.lightHelper = new Three.PointLightHelper(light)
          break
        case 'SpotLight':
          this.lightHelper = new Three.SpotLightHelper(light)
          break
        case 'HemishpereLight':
          this.lightHelper = new Three.HemisphereLightHelper(light)
          break
      }
      if (this.lightHelper) {
        this.scene.add(this.lightHelper)
        this.lightControls.attach(light)
        this.lightControls.addEventListener('dragging-changed', (event) => {
          this.controls.enabled = !event.value
        })
        this.scene.add(this.lightControls)
      }
    },
    changeExtMaterial (material) {
      if (!material) {
        return
      }
      material = this.materials.find((mat) => {
        return mat.id === material.id
      })
      var materialLoader = new Three.MaterialLoader()
      var texture = materialLoader.parse(material.material ? material.material : material.materialJson)
      this.chosenTextures[1] = material.id
      this.currentProduct.textures[this.tabMaterial].textures = this.chosenTextures
      this.objectLoaded.children[1].material = texture
      if (material.envMapPath && material.materialJson.envMapIntensity) {
        var textureLoader = new Three.TextureLoader()
        textureLoader.load(this.$axios.defaults.baseURL + '/downloadSvg?filename=' + material.envMapPath, (envMap) => {
          envMap.mapping = Three.EquirectangularReflectionMapping
          this.objectLoaded.children[1].material.envMap = envMap
          this.objectLoaded.children[1].material.needsUpdate = true
        })
      }
      if (this.currentProduct.textures[this.tabMaterial].colorList.length > 0) {
        this.changeColor(this.currentProduct.textures[this.tabMaterial].colorList[0].color)
        this.changeEmissive(this.currentProduct.textures[this.tabMaterial].colorList[0].emissive)
      }
      this.objectLoaded.children[1].material.needsUpdate = true
    },
    changeIntMaterial (material) {
      if (!material) {
        return
      }
      material = this.materials.find((mat) => {
        return mat.id === material.id
      })
      var materialLoader = new Three.MaterialLoader()
      var texture = materialLoader.parse(material.material ? material.material : material.materialJson)
      this.chosenTextures[0] = material.id
      this.currentProduct.textures[this.tabMaterial].textures = this.chosenTextures
      this.objectLoaded.children[0].material = texture
      if (material.envMapPath && texture.envMapIntensity) {
        var textureLoader = new Three.TextureLoader()
        textureLoader.load(this.$axios.defaults.baseURL + '/downloadSvg?filename=' + material.envMapPath, (envMap) => {
          envMap.mapping = Three.EquirectangularReflectionMapping
          this.objectLoaded.children[0].material.envMap = envMap
          this.objectLoaded.children[0].material.needsUpdate = true
        })
      }
      if (this.currentProduct.textures[this.tabMaterial].colorList.length > 0) {
        this.changeColor(this.currentProduct.textures[this.tabMaterial].colorList[0].color)
        this.changeEmissive(this.currentProduct.textures[this.tabMaterial].colorList[0].emissive)
      }
      this.objectLoaded.children[0].material.needsUpdate = true
    },
    changeColorChildren (child, index, fromHtml) {
      if (!this.otherColors || !this.otherColors[index]) return
      var color = this.otherColors[index].hex
      this.currentProduct.textures[this.tabMaterial]['otherColors'] = JSON.parse(JSON.stringify(this.otherColors))
      child.material.color = new Three.Color(color)
      if (fromHtml) {
        this.changeEmissiveChildren(child, index, false)
      }
    },
    changeEmissiveChildren (child, index, fromHtml) {
      if (!this.otherStrokes || !this.otherStrokes[index]) return
      var color = this.otherStrokes[index].hex
      this.currentProduct.textures[this.tabMaterial]['otherEmissivesColors'] = JSON.parse(JSON.stringify(this.otherStrokes))
      if (child.material.emissive) {
        child.material.emissive = new Three.Color(color)
        if (fromHtml) {
          this.changeColorChildren(child, index, false)
        }
      }
    },
    changeMaterial (child, index) {
      var material = this.otherTextures[index]
      if (!material || !child) {
        return
      }
      material = this.materials.find((mat) => {
        return mat.id === material.id
      })
      var materialLoader = new Three.MaterialLoader()
      var texture = materialLoader.parse(material.material ? material.material : material.materialJson)
      var indexInChildren = 2 + index
      this.chosenTextures[indexInChildren] = material.id
      this.currentProduct.textures[this.tabMaterial].textures = this.chosenTextures
      child.material = texture
      if (this.otherStrokes[index]) {
        this.changeColorChildren(child, index, true)
      } else {
        this.otherStrokes[index] = {}
        this.otherColors[index] = {}
      }
      if (material.envMapPath && texture.envMapIntensity) {
        var textureLoader = new Three.TextureLoader()
        textureLoader.load(this.$axios.defaults.baseURL + '/downloadSvg?filename=' + material.envMapPath, (envMap) => {
          envMap.mapping = Three.EquirectangularReflectionMapping
          child.material.envMap = envMap
          child.material.needsUpdate = true
        })
      }
      if (this.colors) {
        this.changeColor(this.colors.color)
        this.changeEmissive(this.colors.emissive)
      } else {
        if (this.currentProduct.textures[this.tabMaterial].colorList.length > 0) {
          this.changeColor(this.currentProduct.textures[this.tabMaterial].colorList[0].color)
          this.changeEmissive(this.currentProduct.textures[this.tabMaterial].colorList[0].emissive)
        }
      }
      child.material.needsUpdate = true
    },
    getFileName (name) {
      if (!name) return ''
      name = name.split('_')
      name.shift()
      return name.join('')
    },
    addImpression () {
      this.impressions.push({ sku: 0, hauteur: '5cm' })
      this.patronConfigList.push({ height: 1, width: 1, pdf: {}, psd: {}, ai: {} })
      this.fileInputs.push({ ai: {}, pdf: {}, psd: {} })
      this.maskConfigList.push({ top: 1, left: 1, width: 1, height: 1 })
    },
    removeImpression (index) {
      this.impressions.splice(index, 1)
      this.patronConfigList.splice(index, 1)
      this.maskConfigList.splice(index, 1)
    },
    getCurrentProduct () {
      this.$store.dispatch('getProduct', this.$route.params.id).then(() => {
        this.currentProduct = this.$store.getters['GET_CURRENT_PRODUCT']()
        if (this.currentProduct === null) {
          this.$emit('notify', {
            color: 'red',
            text: this.$t('Messages.ProductNotFound')
          })
          this.$router.push({ name: 'Products' })
        } else {
          this.$store.dispatch('getAllMeasures').then(() => {
            this.measures = this.$store.getters['GET_MEASURES']()
          })
          this.otherColors = this.currentProduct.textures.length > 0 && this.currentProduct.textures[0]['otherColors'] ? JSON.parse(JSON.stringify(this.currentProduct.textures[0]['otherColors'])) : null
          if (this.otherColors === null) {
            this.otherColors = []
          }
          this.otherStrokes = this.currentProduct.textures.length > 0 && this.currentProduct.textures[0]['otherEmissivesColors'] ? JSON.parse(JSON.stringify(this.currentProduct.textures[0]['otherEmissivesColors'])) : null
          if (this.otherStrokes === null) {
            this.otherStrokes = []
          }
          this.addProduct()
          this.addSocle()
          if (this.currentProduct.settingsCamera) {
            this.cameraConfig = this.currentProduct.settingsCamera
            this.updateCamera()
            this.camera.position.x = this.cameraConfig.position.x
            this.camera.position.y = this.cameraConfig.position.y
            this.camera.position.z = this.cameraConfig.position.z
            this.camera.updateProjectionMatrix()
          } else {
            this.updateCamera()
          }
          if (this.currentProduct.uvmapList.length > 0) {
            this.maskConfigList = this.currentProduct.uvmapList
          } else {
            this.currentProduct.uvmapList = this.maskConfigList
          }
          this.addLights()
          if (this.currentProduct.patronList.length > 0) {
            this.patronConfigList = this.currentProduct.patronList
          } else {
            this.currentProduct.patronList = this.patronConfigList
          }
          if (this.currentProduct.impressions.length > 0) {
            this.impressions = this.currentProduct.impressions
          } else {
            this.currentProduct.impressions = this.impressions
          }
          if (this.currentProduct.textures.length > 0) {
            this.productMaterials = this.currentProduct.textures
          } else {
            this.currentProduct.textures = this.productMaterials
          }
          this.patronConfigList.forEach((patronConfig) => {
            this.fileInputs.push({
              ai: patronConfig.ai ? new File(['placeholder'], patronConfig.ai, { type: 'placeholder' }) : {},
              pdf: patronConfig.pdf ? new File(['placeholder'], patronConfig.pdf, { type: 'placeholder' }) : {},
              psd: patronConfig.psd ? new File(['placeholder'], patronConfig.psd, { type: 'placeholder' }) : {}
            })
          })
        }
        this.$store.dispatch('getAllSites').then(() => {
          this.sites = this.$store.getters['GET_SITES']()
          this.languageList = []
          this.sites.forEach((site) => {
            site.languageList.forEach((langToAdd) => {
              if (!this.languageList.find((lang) => {
                return lang.value === langToAdd.value
              })) {
                this.languageList.push(langToAdd)
              }
            })
          })
          this.languageList.forEach((lang) => {
            if (!this.currentProduct.labelList.find((langLabel) => {
              return langLabel.langValue === lang.value
            })) {
              this.currentProduct.labelList.push({ langValue: lang.value, label: '' })
            }
          })
        })
      })
    },
    addObj () {
      var reader = new FileReader()
      reader.onload = async () => {
        this.addProduct(reader.result)
        this.deleteProductObjects(this.scene.children)
      }
      reader.readAsText(this.inputFileObj)
    },
    async persistObj () {
      if (!this.currentProduct.id || !this.hasModelChanged) {
        return
      }

      var reader = new FileReader()
      this.sceneLoading = true
      return new Promise((resolve, reject) => {
        reader.onload = () => {
          try {
            var data = new FormData()
            var blob = new Blob([reader.result])
            data.append('fileName', this.inputFileObj.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
            data.append('id', this.currentProduct.id)
            data.append('fileType', 'obj')
            data.append('file', blob)

            this.$store.dispatch('uploadProductObject3D', data)
              .then(resolve)
              .catch((error) => {
                console.error('Error occured', error)
                reject(error)
              })
          } catch (error) {
            console.error('Error occured during onload')
            reject(error)
          }
        }

        reader.onerror = (error) => {
          reject(error)
        }
        reader.readAsArrayBuffer(this.inputFileObj)
      })
    },
    deleteProductObjects (children) {
      children.forEach(child => {
        if (child instanceof Three.Mesh && child.name !== 'socle') {
          child.geometry.dispose()
          if (Array.isArray(child.material)) {
            child.material.forEach(mat => mat.dispose())
          } else {
            child.material.dispose()
          }
          this.scene.remove(child)
        } else if (child instanceof Three.Group) {
          this.deleteProductObjects(child.children)
          this.scene.remove(child)
        }
      })
    },
    updatePosPatron () {
      this.currentProduct.patronList = this.patronConfigList
    },
    updateDistanceCamera () {
      if (this.sliderModificationState === 'min' || (!this.sliderModificationState && parseInt(this.controls.minDistance) !== this.cameraConfig.distance[0])) { // modif min
        this.controls.maxDistance = this.cameraConfig.distance[0]
        this.controls.minDistance = this.cameraConfig.distance[0]
        this.sliderModificationState = 'min'
      } else {
        this.controls.minDistance = this.cameraConfig.distance[1]
        this.controls.maxDistance = this.cameraConfig.distance[1]
        this.sliderModificationState = 'max'
      }
    },
    updateAngleCamera () {
      if (this.controls) {
        if (this.sliderModificationState === 'min' || (!this.sliderModificationState && parseFloat(this.controls.minPolarAngle) !== this.cameraConfig.polarAngle[0])) { // modif min
          this.controls.maxPolarAngle = this.cameraConfig.polarAngle[0]
          this.controls.minPolarAngle = this.cameraConfig.polarAngle[0]
          this.sliderModificationState = 'min'
        } else {
          this.controls.minPolarAngle = this.cameraConfig.polarAngle[1]
          this.controls.maxPolarAngle = this.cameraConfig.polarAngle[1]
          this.sliderModificationState = 'max'
        }
      }
    },
    updateCamera () {
      this.sliderModificationState = null
      this.controls.minDistance = this.cameraConfig.distance[0]
      this.controls.maxDistance = this.cameraConfig.distance[1]
      this.controls.minPolarAngle = parseFloat(this.cameraConfig.polarAngle[0])
      this.controls.maxPolarAngle = parseFloat(this.cameraConfig.polarAngle[1])
      this.cameraConfig.target.x = parseInt(this.cameraConfig.target.x)
      this.cameraConfig.target.y = parseInt(this.cameraConfig.target.y)
      this.cameraConfig.target.z = parseInt(this.cameraConfig.target.z)
      this.controls.target.set(this.cameraConfig.target.x, this.cameraConfig.target.y, this.cameraConfig.target.z)
      this.currentProduct.settingsCamera = this.cameraConfig
      this.cameraLoaded = true
    },
    setDefaultCameraPosition () {
      this.cameraConfig.position.x = this.camera.position.x
      this.cameraConfig.position.y = this.camera.position.y
      this.cameraConfig.position.z = this.camera.position.z
    },
    init () {
      this.container = this.$refs.container

      this.camera = new Three.PerspectiveCamera(40, this.container.clientWidth / this.container.clientHeight, 1, 1000)

      this.scene = new Three.Scene()

      this.renderer = new Three.WebGLRenderer({ antialias: true })
      this.renderer.setSize(this.container.clientWidth, this.container.clientHeight)
      this.renderer.physicallyCorrectLights = true
      this.renderer.outputEncoding = Three.sRGBEncoding
      this.renderer.setClearColor(0x000000)
      this.renderer.clearDepth()
      this.container.appendChild(this.renderer.domElement)

      this.controls = new OrbitControls(this.camera, this.renderer.domElement)
      this.controls.enablePan = false
      this.controls.update()
      window.onresize = () => {
        this.widthScene = (window.innerWidth / 2)
        this.heightScene = window.innerHeight
        this.camera.aspect = this.widthScene / window.innerHeight
        this.camera.updateProjectionMatrix()
        this.renderer.setSize(this.container.clientWidth, this.container.clientHeight)
      }
    },
    initCanvas () {
      this.canvasdiv = this.$refs.canvasdiv

      this.canvas = new fabric.Canvas('canvas')
      this.canvas.on({
        'object:modified': (e) => {
        },
        'after:render': () => {
          this.objectLoaded.children[2].material.map.needsUpdate = true
          this.objectLoaded.children[3].material.map.needsUpdate = true
        }
      })
      this.rectmask = new fabric.Rect({ fill: '#555555', visible: false })
      this.txtmask = new fabric.Text('Zone de personnalisation', {
        fontSize: 30,
        visible: false
      })
      this.canvas.add(this.rectmask)

      var lineHori = new fabric.Rect({
        left: this.rectmask.left,
        top: this.rectmask.height / 2 + this.rectmask.top,
        originX: 'left',
        originY: 'top',
        width: this.rectmask.width,
        height: 1,
        angle: 0,
        strokeWidth: 1,
        fill: 'rgba(228, 228, 0, 1)',
        stroke: 'rgba(255, 0, 0, 0)',
        visible: false,
        transparentCorners: false,
        name: 'lineHori'
      })
      this.canvas.add(lineHori)
      lineHori.bringToFront()
      var lineCenter = new fabric.Rect({
        left: 0,
        top: this.rectmask.top,
        originX: 'left',
        originY: 'top',
        scaleY: 1,
        width: 1,
        height: this.rectmask.height,
        angle: 0,
        strokeWidth: 1,
        fill: 'rgba(228, 228, 0, 1)',
        stroke: 'rgba(255, 0, 0, 0)',
        visible: false,
        transparentCorners: false
      })

      var lineCenter3 = fabric.util.object.clone(lineCenter)
      lineCenter3.name = 'lineCenter3'
      lineCenter3.left = this.rectmask.width / 2 + this.rectmask.left
      this.canvas.add(lineCenter3)
      lineCenter3.bringToFront()

      this.objectLoaded.children[2].material.map = new Three.CanvasTexture(this.canvasdiv)
      this.objectLoaded.children[3].material.map = new Three.CanvasTexture(this.canvasdiv)

      this.canvas.add(this.txtmask)
    },
    updateWidthMask (index) {
      const maskConfig = this.maskConfigList[index]
      const patrongConfig = this.patronConfigList[index]

      if (patrongConfig.height && patrongConfig.width) {
        const newHeight = maskConfig.width * patrongConfig.height / patrongConfig.width
        if (newHeight <= 1024) {
          this.$nextTick(() => {
            maskConfig.height = newHeight
            this.updatePosMask(maskConfig)
          })
        } else {
          maskConfig.height = 1024
          this.updateHeightMask(index)
        }
      }
    },
    updateHeightMask (index) {
      const maskConfig = this.maskConfigList[index]
      const patrongConfig = this.patronConfigList[index]

      if (patrongConfig.height && patrongConfig.width) {
        const newWidth = maskConfig.height * patrongConfig.width / patrongConfig.height
        if (newWidth <= 1024) {
          this.$nextTick(() => {
            maskConfig.width = newWidth
            this.updatePosMask(maskConfig)
          })
        } else {
          maskConfig.width = 1024
          this.updateWidthMask(index)
        }
      }
    },
    updatePosMask (maskConfig) {
      this.rectmask.top = parseInt(maskConfig.top)
      this.rectmask.left = parseInt(maskConfig.left)
      var scale = this.rectmask.getObjectScaling()
      this.rectmask.set('height', parseInt(maskConfig.height) / scale.scaleY)
      this.rectmask.set('width', parseInt(maskConfig.width) / scale.scaleX)
      this.currentProduct.uvmapList = this.maskConfigList
      this.rectmask.setCoords()
      this.updatePosTxt()

      var oldLines = this.canvas.getObjects().filter((o) => {
        return o.name ? o.name.indexOf('line') !== -1 : false
      })
      oldLines.forEach((line) => {
        this.canvas.remove(line)
      })
      var lineHori = new fabric.Rect({
        left: this.rectmask.left,
        top: this.rectmask.height / 2 + this.rectmask.top,
        originX: 'left',
        originY: 'top',
        width: this.rectmask.width,
        height: 1,
        angle: 0,
        strokeWidth: 1,
        fill: 'rgba(228, 228, 0, 1)',
        stroke: 'rgba(255, 0, 0, 0)',
        visible: this.showReperes,
        transparentCorners: false,
        name: 'lineHori'
      })
      this.canvas.add(lineHori)
      lineHori.bringToFront()
      var lineCenter = new fabric.Rect({
        left: 0,
        top: this.rectmask.top,
        originX: 'left',
        originY: 'top',
        scaleY: 1,
        width: 1,
        height: this.rectmask.height,
        angle: 0,
        strokeWidth: 1,
        fill: 'rgba(228, 228, 0, 1)',
        stroke: 'rgba(255, 0, 0, 0)',
        transparentCorners: false,
        visible: this.showReperes
      })

      var lineCenter3 = fabric.util.object.clone(lineCenter)
      lineCenter3.name = 'lineCenter3'
      lineCenter3.left = this.rectmask.width / 2 + this.rectmask.left
      this.canvas.add(lineCenter3)
      lineCenter3.bringToFront()

      this.canvas.renderAll()
    },
    updatePosTxt () {
      this.txtmask.top = this.rectmask.top + this.rectmask.height / 2.5
      this.txtmask.left = this.rectmask.left + this.rectmask.width / 5
      this.canvas.renderAll()
    },
    addLights () {
      if (this.currentProduct.lightList && this.currentProduct.lightList.length > 0) {
        var loader = new Three.ObjectLoader()
        this.currentProduct.lightList.forEach((light) => {
          var lightToAdd = loader.parse(light)
          this.scene.add(lightToAdd)
          this.lights.push(lightToAdd)
        })
      } else {
        this.ambientLight = new Three.AmbientLight(0xFFFFFF, 1)

        this.directionalLight = new Three.DirectionalLight(0xffffff, 0.6)
        this.directionalLight.position.set(10, 15, 10)
        this.directionalLight2 = new Three.DirectionalLight(0xffffff, 0.6)
        this.directionalLight2.position.set(-10, 15, -10)

        this.directionalLight.castShadow = true
        this.scene.add(this.ambientLight)
        this.lights.push(this.ambientLight)
        this.scene.add(this.directionalLight)
        this.lights.push(this.directionalLight)
        this.scene.add(this.directionalLight2)
        this.lights.push(this.directionalLight2)
      }
    },
    addSocle () {
      var geometry = new Three.CylinderGeometry(10, 10, 0.2, 32)

      const textureImage = require('../assets/socleVide.png')
      const texture = new Three.TextureLoader().load(textureImage)

      this.socleMaterial = new Three.MeshPhysicalMaterial({
        color: new Three.Color('rgb(255,255,255)'),
        emissive: new Three.Color('rgb(0,0,0)'),
        side: Three.BackSide,
        map: texture
      })

      var mesh = new Three.Mesh(geometry, this.socleMaterial)

      mesh.position.y = -0.1
      mesh.name = 'socle'
      this.scene.add(mesh)
      this.socle = mesh
    },
    async addProduct (objData) {
      var objLoader = new OBJLoader()
      if (!objData) {
        objData = await this.$store.dispatch('download', this.currentProduct.objPath)
      }
      var object3d = objLoader.parse(objData)
      object3d.scale.x = 1
      object3d.scale.y = 1
      object3d.scale.z = 1

      // Init des materiaux / séparation en 4 children + les autres childrens userless
      object3d.children.reverse()
      var indexChildToClone = 0 // 0 pour a terme
      var tempChild = object3d.children[indexChildToClone]
      var childrenUseless = object3d.children.filter((child, index) => {
        return index !== indexChildToClone
      })
      object3d.children = []
      object3d.add(tempChild.clone()) // product interieur
      object3d.add(tempChild.clone()) // product extérieur
      object3d.add(tempChild.clone()) // texture extérieur
      object3d.add(tempChild.clone()) // texture intérieur
      childrenUseless.forEach((child) => {
        child.material = new Three.MeshPhysicalMaterial({
          color: new Three.Color('rgb(6, 6, 6)'),
          emissive: new Three.Color('rgb(6, 6, 6)'),
          vertexColors: false,
          flatShading: false,
          blending: Three.NormalBlending
        })
        object3d.add(child)
      })
      object3d.children[2].material = new Three.MeshMatcapMaterial({
        color: new Three.Color('rgb(255, 255, 255)'),
        transparent: true,
        side: Three.FrontSide,
        opacity: 1,
        alphaTest: 0.1
      })
      object3d.children[3].material = new Three.MeshMatcapMaterial({
        color: new Three.Color('rgb(255, 255, 255)'),
        transparent: true,
        side: Three.BackSide,
        opacity: 0.2,
        alphaTest: 0.1
      })

      object3d.children[0].scale.x = 0.99
      object3d.children[0].scale.y = 1
      object3d.children[0].scale.z = 0.99
      object3d.children[1].scale.x = 1
      object3d.children[1].scale.y = 1
      object3d.children[1].scale.z = 1
      object3d.children[2].scale.x = 1
      object3d.children[2].scale.y = 1
      object3d.children[2].scale.z = 1
      object3d.children[3].scale.x = 0.98
      object3d.children[3].scale.y = 1
      object3d.children[3].scale.z = 0.98

      this.objectLoaded = object3d
      this.$store.dispatch('getAllMaterials').then(() => {
        this.materials = this.$store.getters['GET_MATERIALS']()
        if (this.currentProduct.textures.length === 0) {
          this.currentProduct.textures.push({
            sku: '',
            name: 'Matériau',
            textures: [
                this.materials[0],
                this.materials[0]
            ]
          })
        }
        this.chosenTextures = this.currentProduct.textures[0].textures
        while (this.chosenTextures.length < (this.objectLoaded.children.length - 2)) {
          this.chosenTextures.push('')
        }
        this.currentProduct.textures[0].textures.forEach((texture, indexTexture) => {
          if (indexTexture === 1 && texture) {
            this.extTexture = this.materials.find((material) => {
              return material.id === texture
            })
            this.changeExtMaterial(this.extTexture)
          } else if (indexTexture === 0 && texture) {
            this.intTexture = this.materials.find((material) => {
              return material.id === texture
            })
            this.changeIntMaterial(this.intTexture)
          } else if (indexTexture >= 2 && texture) {
            this.otherTextures[indexTexture - 2] = this.materials.find((material) => {
              return material.id === texture
            })
            // this.otherColors = this.
            this.changeMaterial(this.objectLoaded.children[indexTexture + 2], indexTexture - 2)
          }
        })

        if (this.currentProduct.textures.length > 0 && this.currentProduct.textures[0].colorList) {
          this.changeEmissive(this.currentProduct.textures[0].colorList[0].emissive)
          this.changeColor(this.currentProduct.textures[0].colorList[0].color)
        } else {
          this.currentProduct.textures[0].colorList = []
        }
      })
      const defaultPrintValues = { fullHeight: 1, printHeight: 1, perimeter: 1, topOffset: 1 }
      const printValues = this.currentProduct.printValuesList && this.currentProduct.printValuesList.length > 0 ? this.currentProduct.printValuesList[0] : defaultPrintValues
      const { fullHeight, printHeight, perimeter, topOffset } = printValues
      this.uvMapManager = new UvMapManager(
        object3d.children[0].geometry,
        fullHeight,
        printHeight,
        perimeter,
        topOffset
        )
      this.scene.add(object3d)
      this.initCanvas()
      this.uvMapManager.loadUVMap()
      this.updatePosMask(this.maskConfigList[0])
      this.sceneLoading = false
    },
    uploadPatrons (files, index = 0, quitAfter) {
      if (index >= files.length) {
        this.$emit('notify', {
          color: 'green',
          text: this.$t('Messages.ProductSaved')
        })
        if (quitAfter) {
          EventBus.$off('sauvegarder')
          EventBus.$off('sauvegarderquitter')
          EventBus.$off('toggleReperes')
          this.$router.push('/Products')
        }
        this.sceneLoading = false
        return
      }
      var reader = new FileReader()

      reader.onload = () => {
        var data = new FormData()
        var blob = new Blob([reader.result])
        data.append('fileName', files[index].file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
        data.append('index', '' + files[index].sku)
        data.append('id', this.currentProduct.id)
        data.append('extension', files[index].type)
        data.append('file', blob)
        this.$store.dispatch('uploadProductPatron', data).then(() => {
          this.uploadPatrons(files, ++index, quitAfter)
        })
      }
      // read the file as text using the reader
      reader.readAsArrayBuffer(files[index].file)
    },
    async saveProduct (quitAfter) {
      this.sceneLoading = true
      var patronsToAdd = []

      this.fileInputs.forEach((fileInput, index) => {
        if (fileInput.ai && fileInput.ai.name && fileInput.ai.type !== 'placeholder') {
          patronsToAdd.push({ file: fileInput.ai, type: 'ai', sku: index })
        }
        if (fileInput.psd && fileInput.psd.name && fileInput.psd.type !== 'placeholder') {
          patronsToAdd.push({ file: fileInput.psd, type: 'psd', sku: index })
        }
        if (fileInput.pdf && fileInput.pdf.name && fileInput.pdf.type !== 'placeholder') {
          patronsToAdd.push({ file: fileInput.pdf, type: 'pdf', sku: index })
        }
      })
      this.currentProduct.lightList = this.lights

      if (this.currentProduct.uvmapType === 'auto' && this.hasModelChanged) {
        this.updateUvmap(this.maskConfigList[this.tabMaskConfig], this.tabMaskConfig)
      }

      const data = {}
      if (this.uvMaps.length !== 0) {
        const { topOffset, fullHeight, printHeight, perimeter } = this.uvMapManager
        const printValues = { topOffset, fullHeight, printHeight, perimeter }
        this.currentProduct.printValuesList = [printValues]
        data.uvMaps = this.uvMaps
      }

      data.product = this.currentProduct
      if (this.currentProduct.textures && this.currentProduct.textures.length > 0) {
        const initialTexture = this.currentProduct.textures[0]
        if (initialTexture.colorList && initialTexture.colorList.length > 0) {
          data.product.productRef = initialTexture.colorList[0].productRef
        }
      }

      this.$store.dispatch('editProduct', data).then(async () => {
        if (this.hasModelChanged) {
          await this.persistObj()
        }
        if (patronsToAdd.length) {
          this.uploadPatrons(patronsToAdd, 0, quitAfter)
        } else {
          if (quitAfter) {
            EventBus.$off('sauvegarder')
            EventBus.$off('sauvegarderquitter')
            EventBus.$off('toggleReperes')
            this.$router.push('/Products')
          }
          this.sceneLoading = false
          this.$emit('notify', {
            color: 'green',
            text: this.$t('Messages.ProductSaved')
          })
        }
        await this.$store.dispatch('getProduct', this.currentProduct.id)
        this.currentProduct = this.$store.getters['GET_CURRENT_PRODUCT']()
        this.inputFileObj = {}
        this.showOriginalFileName = true
      }).catch((err) => {
        this.sceneLoading = false
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + err.response.data)
        })
      })
    },
    addColor () {
      this.currentProduct.textures[this.tabMaterial].colorList.push({
        sku: '',
        color: '#FFFFFF',
        emissive: '#FFFFFF',
        preview: '#FFFFFF',
        productRef: ''
      })
      this.colors = this.currentProduct.textures[this.tabMaterial].colorList[this.currentProduct.textures[this.tabMaterial].colorList.length - 1]
      this.changeColor(this.colors.color)
      this.changeEmissive(this.colors.emissive)
    },
    addMaterial () {
      if (!allowAddingMaterials) return
      this.currentProduct.textures.push(JSON.parse(JSON.stringify(this.currentProduct.textures[0])))
      this.currentProduct.textures[this.currentProduct.textures.length - 1].sku = ''
      this.currentProduct.textures[this.currentProduct.textures.length - 1].name = 'Matériau'
      this.currentProduct.textures[this.currentProduct.textures.length - 1].mappingType = ''
    },
    editColor (colors) {
      this.colors = colors
      this.changeColor(this.colors.color)
      this.changeEmissive(this.colors.emissive)
    },
    removeColor (index) {
      this.currentProduct.textures[this.tabMaterial].colorList.splice(index, 1)
    },
    removeMaterial: function (index) {
      this.currentProduct.textures.splice(index, 1)
      this.$nextTick(() => {
        this.tabMaterial = this.tabMaterial === 0 ? 1 : 0
      })
    },
    changeEmissive (color) {
      if (this.objectLoaded) {
        if (this.objectLoaded.children[1].material.emissive) {
          this.objectLoaded.children[1].material.emissive = new Three.Color(color)
        }
        if (this.objectLoaded.children[0].material.emissive) {
          this.objectLoaded.children[0].material.emissive = new Three.Color(color)
        }
      }
    },
    changeColor (color) {
      if (this.objectLoaded) {
        this.objectLoaded.children[1].material.color = new Three.Color(color)
        this.objectLoaded.children[0].material.color = new Three.Color(color)
      }
    },
    animate: function () {
      requestAnimationFrame(this.animate)
      this.controls.update()
      this.renderer.render(this.scene, this.camera)
    },
    toggleReperes () {
      this.canvas.getObjects().forEach((object) => {
        if (object.name && object.name.indexOf('line') !== -1) {
          object.visible = !object.visible
        }
      })
      this.canvas.requestRenderAll()
      this.showReperes = !this.showReperes
    },
    switchUvmapType () {
      if (this.currentProduct.uvmapType === 'auto') {
        this.updateUvmap(this.maskConfigList[this.tabMaskConfig], this.tabMaskConfig)
      }
    }
  },
  mounted () {
    EventBus.$emit('reperesOff')
    EventBus.$on('sauvegarder', this.saveProduct)
    EventBus.$on('sauvegarderquitter', () => {
      this.saveProduct(true)
    })
    EventBus.$on('toggleReperes', this.toggleReperes)
    this.init()
    this.animate()
    this.getCurrentProduct()
  },
  setup () {
    return {
      allowAddingMaterials
    }
  }
}
</script>

<style>
#threescene .canvas-container {
  display: none
}

.row {
  margin: 0px;
}

.v-menu__content {
  max-width: 500%
}
</style>
